import { render, staticRenderFns } from "./HistoricalArticlesCreation.vue?vue&type=template&id=0e0e21e7"
import script from "./HistoricalArticlesCreation.vue?vue&type=script&lang=js"
export * from "./HistoricalArticlesCreation.vue?vue&type=script&lang=js"
import style0 from "./HistoricalArticlesCreation.vue?vue&type=style&index=0&id=0e0e21e7&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports